function checkLastVisit() {
	const currentTime = new Date().getTime();
	const lastVisit = localStorage.getItem('av_last_visit');

	if (lastVisit && currentTime - lastVisit < 30 * 60 * 1000) {
		return true;
	}

	localStorage.setItem('av_last_visit', currentTime);
	return false;
}

function unSticky(element, header) {
	if (element && header) {
		header.style.top = '0px';
		element.classList.remove('sticky');
	}
}

/**
 * Реализует движение верхнего баннера в течении 5 секунд при прокрутке страниицы
 *
 */
document.addEventListener('DOMContentLoaded', () => {
	const STICKY_CLASS = 'sticky';
	const stickyBlock = document.querySelector('.sponsor.container.top');
	const stickyAside = document.querySelector('.sponsor-list__item-sticky');
	const fixedHeader = document.querySelector('.header');
	const DELAY = 5000;

	let isVisited = checkLastVisit();
	let isStart = !isVisited;

	function handleScroll() {
		if (isStart) {
			stickyBlock.classList.add(STICKY_CLASS);
			const offsetHeight = stickyBlock.offsetHeight;

			if (stickyAside) stickyAside.style.top = `${offsetHeight + 80}px`;
			if (fixedHeader) fixedHeader.style.top = `${offsetHeight}px`;

			setTimeout(() => {
				unSticky(stickyBlock, fixedHeader);
				if (stickyAside) stickyAside.style.top = '80px';
			}, DELAY);

			isStart = false;
		}
	}

	if (isStart && stickyBlock && stickyBlock.offsetHeight > 0) {
		window.addEventListener('scroll', handleScroll);
	} else {
		unSticky(stickyBlock, fixedHeader);
	}
});
